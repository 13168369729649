<template>
  <ElButton
    :type="messageSuccessfullySent ? 'success' : 'info'"
    plain
    :loading="sendingMessage"
    @click="sendMessage"
  >
    {{ messageSuccessfullySent ? locale.messageSent : locale.sendMessage }}
  </ElButton>
</template>

<script>
import ElButton from 'element/ElButton'

export default {
  components: {
    ElButton,
  },

  data: () => ({
    messageSuccessfullySent: false,
  }),

  computed: {
    locale: ({ $locale }) => ({
      sendMessage: $locale('chatbot.sendMessage'),
      messageSent: $locale('chatbot.messageSent'),
    }),
    sendingMessage: ({ $store }) => $store.getters['widgetSettings/sendingMessage'],
  },

  watch: {
    sendingMessage (val) {
      if (!val) {
        this.messageSuccessfullySent = true
        setTimeout(() => {
          this.messageSuccessfullySent = false
        }, 1500)
      }
    },
  },

  methods: {
    sendMessage () {
      this.$store.dispatch('widgetSettings/sendMessage')
    },
  },
}
</script>
