<template>
  <DashboardTitle
    class="widget-header"
    :title="locale.title"
  />
</template>

<script>
import DashboardTitle from 'components/layouts/DashboardLayout/components/DashboardTitle'

export default {
  components: {
    DashboardTitle,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('header.title'),
    }),
  },
}
</script>
