import axios from 'axios'
import Echo from 'laravel-echo'
// import * as events from './consts/events'
import * as events from 'lib/pusher/constants/pusher-events'
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import Pusher from 'pusher-js'
// import examples from './examples'

export default class Socket {
  constructor (slug) {
    this.url = `${process.env.VUE_APP_API_URL}broadcasting/auth`
    this.slug = slug
    this.init()
  }

  init () {
    this.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authorizer: (channel) => ({
        authorize: async (socketId, callback) => {
          const params = {
            socket_id: socketId,
            channel_name: channel.name,
            slug: this.slug,
          }

          try {
            const res = await axios.post(this.url, params)
            // eslint-disable-next-line standard/no-callback-literal
            callback(false, res.data)
          }
          catch (err) {
            // eslint-disable-next-line standard/no-callback-literal
            callback(true, err)
          }
        },
      }),
    })
  }

  listen (callback) {
    this.Echo.private(`uplify.ads.${this.slug}`)
      .listen('.widget.params.updated', event => {
        callback(events.WIDGET_UPDATED, { ...event, status: true })
      })
      .listen('.manual.launch', event => {
        callback(events.MANUAL_LAUNCH, event)
      })
      .listen('.auto.launch', event => {
        callback(events.AUTO_LAUNCH, event)
      })
      .listen('.fetching-ad', event => {
        callback(events.FETCHING_AD, event)
      })
      .listen('.chatbot-message-sent', event => {
        callback(events.CHATBOT_MESSAGE_SENT, event)
      })
      .listen('.referral.launch', event => {
        callback(events.REFERRAL_LAUNCH, event)
      })
      .listen('.demo.real.launch', event => {
        callback(events.DEMO_LAUNCH, event)
      })
      .listen('.demo.launch', event => {
        callback(events.DEMO_LAUNCH, event)
      })
      .listen('.demo.referral.launch', event => {
        callback(events.DEMO_LAUNCH, event)
      })

    // test
    // setTimeout(() => {
    //   callback(events.DEMO_LAUNCH, examples.video.data)
    // }, 1000)
  }

  leave () {
    this.Echo.leave(`uplify.ads.${this.slug}`)
  }
}
