<template>
  <WidgetSection>
    <DashboardSection
      class="widget-panel-section"
      :title="locale.title"
    >
      <WidgetPanelMobile v-if="obsDockUrl" />
      <WidgetPanelObs />
    </DashboardSection>
  </WidgetSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import WidgetPanelMobile from 'Streamer/pages/StreamerWidget/components/WidgetPanelMobile'
import WidgetPanelObs from 'Streamer/pages/StreamerWidget/components/WidgetPanelObs'
import WidgetSection from 'Streamer/pages/StreamerWidget/components/WidgetSection'

export default {
  components: {
    WidgetSection,
    DashboardSection,
    WidgetPanelMobile,
    WidgetPanelObs,
  },

  computed: {
    obsDockUrl: ({ $store }) => $store.getters['widgetSettings/obsDockUrl'],
    locale: ({ $locale }) => ({
      title: $locale('panel.title'),
    }),
  },
}
</script>

<style lang="scss" scoped>
.widget-link-section {
  position: relative;

  &__description {
    margin-bottom: 32px;

    &-text {
      margin-bottom: 8px;
    }
  }

  ::v-deep {
    .advice {
      position: absolute;
      left: calc(100% + 24px);
      top: 32px;
    }
  }
}
</style>
