<template>
  <WidgetSection>
    <DashboardSection
      class="widget-advert-settings-section"
    >
      <template #left>
        <div
          class="text-m-bold dashboard-section__title"
        >
          {{ locale.title }}
          <SvgIcon
            v-show="$isMobile && showAdvice"
            name="help-icon"
            width="16px"
            height="16px"
            @click="toggleHint"
          />
        </div>
      </template>
      <div class="text-m-regular widget-advert-settings-section__description">
        <div class="widget-advert-settings-section__description-text">
          {{ locale.description }}
        </div>
        <TextLink
          :href="$t('links.inStreamAdsFormat')"
          target="_blank"
        >
          {{ locale.moreAboutFormats }}
        </TextLink>
      </div>
      <ElForm class="widget-advert-settings-section__switchers">
        <div class="widget-advert-settings-section__switchers-top">
          <WidgetAdPlaybackMode />
          <WidgetManualPlayback v-if="mode === 'manual'" />
          <WidgetBannerTimeout v-if="mode === 'auto'" />
        </div>
        <WidgetBoxSize class="widget-advert-settings-section__box-size" />
        <div class="widget-advert-settings-section__switchers-bottom">
          <WidgetAdvertisingPosition />
        </div>
      </ElForm>
      <WidgetAdvertAdvice
        v-if="showAdvice"
        :hint-active="hintActive"
      />
    </DashboardSection>
  </WidgetSection>
</template>

<script>
import TextLink from 'components/buttons/TextLink'
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElForm from 'element/ElForm'
import WidgetAdPlaybackMode from 'Streamer/pages/StreamerWidget/components/WidgetAdPlaybackMode'
import WidgetAdvertAdvice from 'Streamer/pages/StreamerWidget/components/WidgetAdvertAdvice'
import WidgetAdvertisingPosition from 'Streamer/pages/StreamerWidget/components/WidgetAdvertisingPosition'
import WidgetBannerTimeout from 'Streamer/pages/StreamerWidget/components/WidgetBannerTimeout'
import WidgetBoxSize from 'Streamer/pages/StreamerWidget/components/WidgetBoxSize'
import WidgetManualPlayback from 'Streamer/pages/StreamerWidget/components/WidgetManualPlayback'
import WidgetSection from 'Streamer/pages/StreamerWidget/components/WidgetSection'

export default {
  components: {
    WidgetSection,
    DashboardSection,
    ElForm,
    WidgetAdPlaybackMode,
    WidgetBannerTimeout,
    WidgetAdvertisingPosition,
    WidgetAdvertAdvice,
    WidgetManualPlayback,
    WidgetBoxSize,
    TextLink,
  },

  data: () => ({
    hintActive: false,
  }),

  computed: {
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    showAdvice ({ widget, mode }) {
      return mode === 'manual' && (!widget.enabled || !widget.chatbotEnabled || !widget.adManualEnable)
    },
    mode: ({ widget }) => widget.mode,
    locale: ({ $locale }) => ({
      title: $locale('advertSettings.title'),
      description: $locale('advertSettings.description'),
      moreAboutFormats: $locale('advertSettings.moreAboutFormats'),
    }),
  },

  methods: {
    toggleHint () {
      this.hintActive = !this.hintActive
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-advert-settings-section {
  position: relative;

  &__description {
    margin-bottom: 32px;

    &-text {
      margin-bottom: 8px;
    }
  }

  @media (max-width: $--tablet-portrait) {
    .dashboard-section__left {
      .svg-icon {
        vertical-align: bottom;
        fill: $--color-primary;
      }
    }
  }

  &__box-size {
    margin-bottom: 32px;
  }

  &__switchers {

    ::v-deep {
      .el-form-item {
        margin-bottom: 0;
      }
    }

    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      @media (max-width: $--tablet-portrait) {
        display: block;
      }

      ::v-deep {
        .el-form-item {
          width: calc(50% - 10px);

          @media (max-width: $--tablet-portrait) {
            width: 100%;
            margin-bottom: 24px !important;
          }
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @media (max-width: $--tablet-portrait) {
        display: block;
      }

      ::v-deep {
        .el-form-item {
          width: calc(50% - 10px);

          @media (max-width: $--tablet-portrait) {
            width: 100%;
            margin-bottom: 24px !important;

            &:last-of-type {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }

  ::v-deep {
    .advice {
      position: absolute;
      left: calc(100% + 24px);
      top: 32px;
    }
  }
}
</style>
