<template>
  <WidgetSection>
    <DashboardSection
      class="widget-chatbot-section"
      :title="locale.title"
    >
      <WidgetChatbotDescription />
      <WidgetChatbotTwitch v-if="widget.platform === 'twitch'" />
      <WidgetChatbotYoutube v-if="widget.platform === 'youtube'" />
      <WidgetChatbotTrovo v-if="widget.platform === 'trovo'" />
      <ElAlert
        v-if="error"
        class="widget-chatbot-section__alert"
        type="error"
        show-icon
        :closable="false"
      >
        <div
          v-if="error === 'invalid-platform'"
          v-html="locale.error"
        />
      </ElAlert>
    </DashboardSection>
  </WidgetSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElAlert from 'element/ElAlert'
import WidgetChatbotDescription from 'Streamer/pages/StreamerWidget/components/WidgetChatbotDescription'
import WidgetChatbotTrovo from 'Streamer/pages/StreamerWidget/components/WidgetChatbotTrovo'
import WidgetChatbotTwitch from 'Streamer/pages/StreamerWidget/components/WidgetChatbotTwitch'
import WidgetChatbotYoutube from 'Streamer/pages/StreamerWidget/components/WidgetChatbotYoutube'
import WidgetSection from 'Streamer/pages/StreamerWidget/components/WidgetSection'

export default {
  components: {
    WidgetSection,
    DashboardSection,
    WidgetChatbotTwitch,
    WidgetChatbotYoutube,
    WidgetChatbotTrovo,
    WidgetChatbotDescription,
    ElAlert,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('chatbot.title'),
      description: $locale('chatbot.description'),
      surveyDescription: $locale('chatbot.surveyDescription'),
      helpWithSetup: $locale('chatbot.helpWithSetup'),
      wantAnotherChatbot: $locale('chatbot.wantAnotherChatbot'),
      error: $locale('chatbot.error'),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    error: ({ $route }) => $route.query.error,
  },
}
</script>

<style lang="scss" scoped>
.widget-chatbot-section {
  &__alert {
    margin-top: 8px;

    a {
      color: $--color-danger;
      border-bottom: 1px solid $--color-danger;

      &:hover {
        border-bottom: none;
      }
    }
  }

  :deep {
    .el-alert {
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
</style>
