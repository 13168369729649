<template>
  <WidgetSection>
    <DashboardSection
      class="widget-link-section"
    >
      <template #left>
        <div
          class="text-m-bold dashboard-section__title"
        >
          {{ locale.title }}
          <SvgIcon
            v-show="$isMobile && showAdvice"
            name="help-icon"
            width="16px"
            height="16px"
            @click="toggleHint"
          />
        </div>
      </template>

      <WidgetOptionDrag :url="widget.url" />
      <WidgetOptionLink :url="widget.url" />
      <div class="widget-link-section__preview text-m-regular">{{ locale.preview }}</div>
      <div class="widget-link-section__buttons">
        <WidgetChatbotSendPreview />
      </div>

      <!-- <div class="text-m-regular widget-link-section__description">
        <div class="widget-link-section__description-text">
          {{ locale.description }}
        </div>
        <ElAlert
          class="chatbot-trovo__alert"
          :title="locale.warn"
          type="success"
          show-icon
          :closable="false"
        />
        <TextLink
          :href="$t('links.widgetSetup')"
          target="_blank"
        >
          {{ locale.helpWithSetup }}
        </TextLink>
      </div>
      <WidgetLink :url="widget.url">
        <WidgetChatbotSendPreview />
      </WidgetLink> -->
      <WidgetSettingsAdvice
        v-if="showAdvice"
        :hint-active="hintActive"
      />
    </DashboardSection>
  </WidgetSection>
</template>

<script>
import TextLink from 'components/buttons/TextLink'
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElAlert from 'element/ElAlert'
import WidgetChatbotSendPreview from 'Streamer/pages/StreamerWidget/components/WidgetChatbotSendPreview'
import WidgetLink from 'Streamer/pages/StreamerWidget/components/WidgetLink'
import WidgetSection from 'Streamer/pages/StreamerWidget/components/WidgetSection'
import WidgetSettingsAdvice from 'Streamer/pages/StreamerWidget/components/WidgetSettingsAdvice'
import WidgetOptionDrag from 'Streamer/pages/StreamerWidget/components/WidgetOptionDrag.vue'
import WidgetOptionLink from 'Streamer/pages/StreamerWidget/components/WidgetOptionLink.vue'

export default {
  components: {
    WidgetSection,
    DashboardSection,
    WidgetLink,
    WidgetSettingsAdvice,
    WidgetChatbotSendPreview,
    TextLink,
    ElAlert,
    WidgetOptionDrag,
    WidgetOptionLink,
},

  data: () => ({
    hintActive: false,
  }),

  computed: {
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    showAdvice ({ widget }) {
      return widget.bootstrapped && (!widget.enabled || !widget.chatbotEnabled)
    },
    locale: ({ $locale }) => ({
      title: $locale('widgetLink.title'),
      preview: $locale('widgetLink.preview'),
    }),
  },

  methods: {
    toggleHint () {
      this.hintActive = !this.hintActive
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-link-section {
  position: relative;

  &__description {
    margin-bottom: 32px;

    &-text {
      margin-bottom: 8px;
    }
  }

  @media (max-width: $--tablet-portrait) {
    .dashboard-section__left {
      .svg-icon {
        vertical-align: bottom;
        fill: $--color-primary;
      }
    }
  }

  ::v-deep {
    .el-alert {
      align-items: flex-start;
      margin-bottom: 8px;
      padding: 12px;

      &__content {
        padding-right: 0;
      }

      &__icon:before {
        content: "\e62e";
      }
    }
    .advice {
      position: absolute;
      left: calc(100% + 24px);
      top: 32px;
    }
  }

  &__preview {
    margin-bottom: 16px;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    @media (max-width: $--tablet-portrait) {
      grid-template-columns: 1fr;
    }

    ::v-deep {

      & > div {
        .el-button {
            width: 100%;
            margin: 0;
        }
      }
    }
  }
}
</style>
