<template>
  <ElSelect
    v-model="widget.ignoreCategories"
    :placeholder="$t('placeholder.chooseCategories')"
    no-data-text="Нет категорий"
    multiple
    clearable
    @visible-change="onVisibleChange"
    @remove-tag="onRemove"
  >
    <ElOption
      v-for="category in categories"
      :key="category.id"
      :label="category.title"
      :value="category.id"
    />
  </ElSelect>
</template>

<script>
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'

export default {
  components: {
    ElSelect,
    ElOption,
  },

  computed: {
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    categories ({ $store }) {
      return $store.getters['dictionaries/campaignDictionaries'].categories
    },
  },

  methods: {
    onVisibleChange (value) {
      if (value) return
      this.widget.debouncedUpdate()
    },
    onRemove () {
      this.widget.debouncedUpdate()
    },
  },
}
</script>
