<template>
  <DashboardLayout>
    <WidgetHeader />
    <WidgetStatusSection />
    <WidgetPlatformSection />
    <WidgetLinkSection />
    <WidgetChatbotSection />
    <WidgetAdvertSettingsSection />
    <WidgetPanelSection />
    <WidgetTwitchExtensionSection v-if="widget.platform === 'twitch'" />
    <WidgetStreamDelaySection />
    <WidgetIgnoreCategoriesSection />
  </DashboardLayout>
</template>

<script>
import * as events from 'lib/pusher/constants/pusher-events'
import DashboardLayout from 'components/layouts/DashboardLayout/index.vue'
import WidgetHeader from 'Streamer/pages/StreamerWidget/components/WidgetHeader'
import WidgetAdvertSettingsSection from 'Streamer/pages/StreamerWidget/sections/WidgetAdvertSettingsSection'
import WidgetChatbotSection from 'Streamer/pages/StreamerWidget/sections/WidgetChatbotSection'
import WidgetIgnoreCategoriesSection from 'Streamer/pages/StreamerWidget/sections/WidgetIgnoreCategoriesSection'
import WidgetLinkSection from 'Streamer/pages/StreamerWidget/sections/WidgetLinkSection'
import WidgetPanelSection from 'Streamer/pages/StreamerWidget/sections/WidgetPanelSection'
import WidgetPlatformSection from 'Streamer/pages/StreamerWidget/sections/WidgetPlatformSection'
import WidgetStatusSection from 'Streamer/pages/StreamerWidget/sections/WidgetStatusSection'
import WidgetStreamDelaySection from 'Streamer/pages/StreamerWidget/sections/WidgetStreamDelaySection'
import Socket from 'views/WidgetOld/Socket'

import WidgetTwitchExtensionSection from './sections/WidgetTwitchExtensionSection.vue'

export default {
  components: {
    DashboardLayout,
    WidgetAdvertSettingsSection,
    WidgetChatbotSection,
    WidgetHeader,
    WidgetStatusSection,
    WidgetIgnoreCategoriesSection,
    WidgetLinkSection,
    WidgetPanelSection,
    WidgetPlatformSection,
    WidgetStreamDelaySection,
    WidgetTwitchExtensionSection,
  },

  data: () => ({
    socket: null,
  }),

  computed: {
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
  },

  watch: {
    widget: {
      handler (widget) {
        if (widget.bootstrapped && !this.socket) {
          this.listen()
        }
      },
      deep: true,
    },
  },

  async created () {
    await this.widget.fetch()
  },

  methods: {
    listen () {
      this.socket = new Socket(this.widget.slug)
      this.socket.listen((event, payload) => {
        if (event === events.WIDGET_UPDATED) {
          this.widget.updateData(payload)
        }
      })
    },
  },

  mounted: () => {
    window.scroll(0, 0)
  },
}
</script>
