<template>
  <ElFormItem>
    <template #label>
      {{ locale.fieldLabel }}
    </template>
    <ElInput
      v-model="value"
      :suffix-icon="suffixIcon"
    />
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'

export default {
  components: {
    ElInput,
    ElFormItem,
  },

  data: () => ({
    changed: false,
    updating: false,
    success: false,
  }),

  computed: {
    locale: ({ $locale }) => $locale('widgetDelay'),
    value: {
      get: ({ widget }) => widget.streamDelay,
      set (value) {
        const delay = parseInt(value)

        if (!value) {
          this.widget.streamDelay = value
        }
        else if (delay > 1800) {
          this.widget.streamDelay = 1800
        }
        else if (delay < 0) {
          this.widget.streamDelay = 0
        }
        else {
          this.widget.streamDelay = delay
        }
      },
    },
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    suffixIcon: ({ changed, updating, success }) => {
      if (changed && updating && !success) {
        return 'el-icon-loading'
      }
      if (changed && updating && success) {
        return 'el-icon-check'
      }
      return ''
    },
  },

  created () {
    this.widget.on('change-streamDelay', () => {
      this.changed = true
    })
    this.widget.on('updating', () => {
      this.updating = true
    })
    this.widget.on('update-success', () => {
      this.success = true

      setTimeout(() => {
        this.success = false
        this.updating = false
        this.changed = false
      }, 1000)
    })
  },
}
</script>
