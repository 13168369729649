<template>
  <div class="widget-panel-obs">
    <div class="text-m-bold">
      {{ locale.title }}
    </div>
    <div class="text-m-regular">
      {{ locale.description }}
    </div>

    <WidgetLink :url="url">
      <ReloadButton
        @click="resetLink"
        :loading="reseting"
      >
        {{ locale.resetLink }}
      </ReloadButton>
    </WidgetLink>
  </div>
</template>

<script>
import ReloadButton from 'components/buttons/ReloadButton'
import WidgetLink from 'Streamer/pages/StreamerWidget/components/WidgetLink'

export default {
  components: {
    WidgetLink,
    ReloadButton,
  },

  data: () => ({
    reseting: false,
  }),

  computed: {
    obsDockUrl: ({ $store }) => $store.getters['widgetSettings/obsDockUrl'],
    locale: ({ $locale, $rootLocale }) => ({
      title: $locale('panel.obsVersion.title'),
      description: $locale('panel.obsVersion.description'),
      resetLink: $rootLocale('button.resetLink'),
    }),
    url: ({ obsDockUrl }) => obsDockUrl,
  },

  methods: {
    async resetLink () {
      this.reseting = true

      try {
        await this.$store.dispatch('widgetSettings/refreshObsLink')
      }
      finally {
        this.reseting = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-panel-obs {
  margin-top: 32px;

  .text-m-bold {
    margin-bottom: 8px;
  }

  .text-m-regular {
    margin-bottom: 16px;
  }
}
</style>
