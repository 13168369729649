<template>
  <ElFormItem :label="locale.title">
    <ElSelect
      v-model="widget.advertisingPosition"
      :class="selectTheme"
      :popper-class="selectTheme"
    >
      <ElOption
        v-for="position in positions"
        :key="position.id"
        :label="position.title"
        :value="position.id"
      />
    </ElSelect>
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'
import VueTypes from 'vue-types'

export default {
  components: {
    ElSelect,
    ElOption,
    ElFormItem,
  },

  props: {
    selectTheme: VueTypes.string,
  },

  computed: {
    locale: ({ $rootLocale }) => ({
      title: $rootLocale('views.streamer.widget.advertSettings.pictureInPicturePosition'),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    positions ({ $store }) {
      return $store.getters['dictionaries/campaignDictionaries'].adPositions
    },
  },
}
</script>
