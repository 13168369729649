<template>
  <WidgetSection>
    <DashboardSection
      class="widget-platform-section"
      :title="locale.title"
    >
      <div class="text-m-regular">
        {{ locale.description }}
      </div>
      <br>

      <WidgetPlatformSelect />
    </DashboardSection>
  </WidgetSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import WidgetPlatformSelect from 'components/WidgetPlatformSelect'
import WidgetSection from 'Streamer/pages/StreamerWidget/components/WidgetSection'

export default {
  components: {
    WidgetSection,
    DashboardSection,
    WidgetPlatformSelect,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('widgetPlatform.title'),
      description: $locale('widgetPlatform.description'),
    }),
  },
}
</script>
