<template>
  <Advice
    :title="locale.title"
    :mobile-visible="hintActive"
  >
    <p>
      {{ locale.firstString }}
      <br>
      <br>
      {{ locale.secondString }}
    </p>
  </Advice>
</template>

<script>
import Advice from 'components/Advice'

export default {
  props: {
    hintActive: VueTypes.boolean,
  },

  components: {
    Advice,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('advertSettings.advice.title'),
      firstString: $locale('advertSettings.advice.firstString'),
      secondString: $locale('advertSettings.advice.secondString'),
    }),
  },
}
</script>
