<template>
  <div class="widget-box-size">
    <div class="widget-box-size__top">
      <div class="text-s-regular">
        {{ locale.title }}
      </div>
      <div class="text-s-regular">
        {{ duration }}
      </div>
    </div>

    <div class="widget-box-size__range">
      <div class="widget-box-size__range-line" />
      <div class="widget-box-size__range-points">
        <span
          v-for="item in items"
          :key="item"
          class="widget-box-size__range-point"
          :class="{'widget-box-size__range-point_active': item === widget.boxSize}"
          @click="widget.boxSize = item"
        >
          {{ item }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    min: 3,
    max: 9,
  }),

  computed: {
    locale: ({ $rootLocale }) => ({
      title: $rootLocale('views.streamer.widget.advertSettings.adsBlocksCountTitle'),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    duration ({ widget }) {
      const duration = widget.boxSize * 15
      const localePath = 'views.streamer.widget.advertSettings.adsBlocksCountDuration'
      return this.$t(localePath, { seconds: duration })
    },
    items ({ min, max }) {
      const result = []
      for (let i = min; i <= max; i++) {
        result.push(i)
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-box-size {
  margin-bottom: 12px;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__range {
    &-line {
      height: 2px;
      border-bottom: 2px solid $--color-lightest-gray;
      padding-top: 38px;
    }

    &-points {
      display: flex;
      justify-content: space-between;
      width: calc(100% + 16px);
      margin-left: -8px;
    }

    &-point {
      display: block;
      width: 24px;
      text-align: center;
      padding-bottom: 18px;
      position: relative;
      margin-top: -39px;
      cursor: pointer;
      transition: $--all-transition;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: $--color-lightest-gray;
        border-radius: 50%;
        position: absolute;
        top: 34px;
        left: calc(50% - 4px);
        transition: $--all-transition;
      }

      &_active {
        color: $--color-primary;

        &:before {
          background-color: white;
          box-shadow: 0 0 0 8px $--color-primary;
        }
      }
    }
  }
}
</style>
