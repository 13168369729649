<template>
  <WidgetSection>
    <DashboardSection
      class="widget-ignore-categories-section"
      :title="locale.title"
    >
      <div class="text-m-regular widget-ignore-categories-section__description">
        {{ locale.description }}
      </div>
      <WidgetIgnoreCategories />
    </DashboardSection>
  </WidgetSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import WidgetIgnoreCategories from 'Streamer/pages/StreamerWidget/components/WidgetIgnoreCategories'
import WidgetSection from 'Streamer/pages/StreamerWidget/components/WidgetSection'

export default {
  components: {
    WidgetSection,
    DashboardSection,
    WidgetIgnoreCategories,
  },

  computed: {
    locale ({ $locale }) {
      return {
        title: $locale('ignoreCategories.title'),
        description: $locale('ignoreCategories.description'),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-ignore-categories-section {
  &__description {
    margin-bottom: 22px;
  }
}
</style>
