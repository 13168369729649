<template>
  <div class="chatbot-twitch">
    <div class="chatbot-twitch__button">
      <ElButton
        type="info"
        :loading="togglingChatbot"
        @click="toggleChatbot"
      >
        {{ chatbotConnected ? locale.disconnectNightbot : locale.connectNightbot }}
      </ElButton>

      <template v-if="chatbotConnected">
        <WidgetChatbotSendMessage v-if="isModerator" />
        <WidgetChatbotCheck v-else />
      </template>
    </div>
    <ElAlert
      v-if="chatbotConnected && !isModerator"
      class="chatbot-twitch__alert"
      :title="locale.nightbotAlert"
      type="error"
      show-icon
      :closable="false"
    />
  </div>
</template>

<script>
import ElAlert from 'element/ElAlert'
import ElButton from 'element/ElButton'
import WidgetChatbotCheck from 'Streamer/pages/StreamerWidget/components/WidgetChatbotCheck'
import WidgetChatbotSendMessage from 'Streamer/pages/StreamerWidget/components/WidgetChatbotSendMessage'

export default {
  components: {
    WidgetChatbotSendMessage,
    WidgetChatbotCheck,
    ElButton,
    ElAlert,
  },

  computed: {
    locale: ({ $locale }) => ({
      connectNightbot: $locale('chatbot.connectNightbot'),
      disconnectNightbot: $locale('chatbot.disconnectNightbot'),
      sendMessage: $locale('chatbot.sendMessage'),
      messageSent: $locale('chatbot.messageSent'),
      nightbotAlert: $locale('attention.chatbotDisabled.reasons.addChatbotAsModerator'),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    togglingChatbot: ({ $store }) => $store.getters['widgetSettings/togglingChatbot'],
    isModerator: ({ widget }) => widget.chatbotTwitchIsModerator,
    chatbotConnected: ({ widget }) => widget.chatbotTwitchConnected,
  },

  methods: {
    toggleChatbot () {
      this.$store.dispatch('widgetSettings/toggleChatbot')
    },
  },
}
</script>

<style lang="scss" scoped>
.chatbot-twitch {
  &__button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    @media (max-width: $--tablet-portrait) {
      grid-template-columns: 1fr;
    }

    ::v-deep {
      .el-button {
        margin: 0;
      }
    }
  }

  &__alert {
    width: fit-content;
    margin-top: 8px;

    ::v-deep {

      .el-alert__icon:before {
        content: "\e62e";
      }
    }
  }
}
</style>
