<template>
  <div>
    <div
      v-if="label"
      class="el-form-item__label"
    >
      {{ label }}
    </div>
    <ElButton
      class="widget-send-preview"
      :type="successfullySent ? 'success' : 'info'"
      plain
      :loading="sending"
      @click="sendPreview"
    >
      {{ successfullySent ? locale.sendPreview.success : locale.sendPreview.static }}
    </ElButton>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'
import { Api } from 'lib/api/instance'
import VueTypes from 'vue-types'

export default {
  components: {
    ElButton,
  },

  props: {
    label: VueTypes.string,
  },

  data: () => ({
    successfullySent: false,
    sending: false,
  }),

  computed: {
    locale: ({ $rootLocale }) => ({
      sendPreview: $rootLocale('button.sendPreview'),
    }),
  },

  watch: {
    sending (val) {
      if (!val) {
        this.successfullySent = true
        setTimeout(() => {
          this.successfullySent = false
        }, 1500)
      }
    },
  },

  methods: {
    async sendPreview () {
      try {
        this.sending = true
        await Api.get('ads/widget/demo')
      }
      finally {
        this.sending = false
      }
    },
  },
}
</script>
