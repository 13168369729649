<template>
  <ElFormItem :label="locale.label">
    <ElSelect
      v-model="widget.frequency"
      :class="selectTheme"
      :popper-class="selectTheme"
    >
      <ElOption
        :label="locale.per15min"
        :value="15"
      />
      <ElOption
        :label="locale.per30min"
        :value="30"
      />
      <ElOption
        :label="locale.perHour"
        :value="60"
      />
    </ElSelect>
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'
import VueTypes from 'vue-types'

export default {
  components: {
    ElSelect,
    ElOption,
    ElFormItem,
  },

  props: {
    selectTheme: VueTypes.string,
  },

  computed: {
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    locale: ({ $rootLocale }) => ({
      label: $rootLocale('views.streamer.widget.advertSettings.bannerTimeout'),
      per15min: $rootLocale('helpers.perMin', { minutes: '15' }),
      per30min: $rootLocale('helpers.perMin', { minutes: '30' }),
      perHour: $rootLocale('helpers.perHour'),
    }),
  },
}
</script>
