<template>
  <ElFormItem :label="locale.title">
    <ElButton
      class="manual-playback-button"
      type="primary"
      :disabled="!widget.adManualEnable"
      :loading="sending"
      @click="onClick"
    >
      {{ $t('button.runAd') }}
    </ElButton>
  </ElFormItem>
</template>

<script>
import ElButton from 'element/ElButton'
import ElFormItem from 'element/ElFormItem'

export default {
  components: {
    ElButton,
    ElFormItem,
  },

  data: () => ({
    sending: false,
  }),

  computed: {
    locale: ({ $rootLocale }) => ({
      title: $rootLocale('views.streamer.widget.advertSettings.manualPlaybackTitle'),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
  },

  methods: {
    async onClick () {
      this.sending = true
      await this.$store.dispatch('widgetSettings/sendManualAd')
      this.sending = false
    },
  },
}
</script>

<style lang="scss" scoped>
.manual-playback-button {
  width: 100%;
}
</style>
