<template>
    <ElButton
    class="widget-chatbot-check"
    type="text"
    @click="checkChatbot"
  >
    <div
      class="widget-chatbot-check__icon"
      :class="{'widget-chatbot-check__icon_rotation': checkingChatbot}"
    >
      <SvgIcon
        name="refresh"
        width="12"
        height="12"
      />
    </div>
    {{ locale.checkChatbot }}
  </ElButton>
</template>

<script>
import ElButton from 'element/ElButton'
export default {
  components: {
    ElButton,
  },
  computed: {
    locale: ({ $locale }) => ({
      checkChatbot: $locale('chatbot.checkChatbot'),
    }),
    checkingChatbot: ({ $store }) => $store.getters['widgetSettings/checkingChatbot'],
  },
  methods: {
    async checkChatbot () {
      await this.$store.dispatch('widgetSettings/checkChatbot')
      this.$store.getters['widgetSettings/widget'].fetch()
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-chatbot-check {
  ::v-deep {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__icon {
    margin-right: 10px;
    fill: $--color-primary;

    &_rotation svg {
      animation: rotation 0.5s linear infinite;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}
</style>
