<template>
  <Advice
    type="danger"
    :title="locale.title"
    :mobile-visible="hintActive"
  >
    <template v-if="!widget.enabled">
      <b>{{ locale.widgetDisabled.title }}</b>
      <p>{{ locale.widgetDisabled.reasons.title }}</p>
      <ol>
        <li>{{ locale.widgetDisabled.reasons.broadcast }}</li>
        <li>{{ locale.widgetDisabled.reasons.notSetUp }}</li>
      </ol>
    </template>
    <br v-if="!widget.enabled && !widget.chatbotEnabled">
    <template v-if="!widget.chatbotEnabled">
      <b>{{ locale.chatbotDisabled.title }}</b>
      <p>{{ locale.chatbotDisabled.reasons.toBeConnected }}</p>
      <p>{{ locale.chatbotDisabled.reasons.addChatbotAsModerator }}</p>
    </template>
  </Advice>
</template>

<script>
import Advice from 'components/Advice'

export default {
  props: {
    hintActive: VueTypes.boolean,
  },

  components: {
    Advice,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('attention.title'),
      widgetDisabled: {
        title: $locale('attention.widgetDisabled.title'),
        reasons: {
          title: $locale('attention.widgetDisabled.reasons.title'),
          broadcast: $locale('attention.widgetDisabled.reasons.broadcast'),
          notSetUp: $locale('attention.widgetDisabled.reasons.notSetUp'),
        },
      },
      chatbotDisabled: {
        title: $locale('attention.chatbotDisabled.title'),
        reasons: {
          toBeConnected: $locale('attention.chatbotDisabled.reasons.toBeConnected'),
          addChatbotAsModerator: $locale('attention.chatbotDisabled.reasons.addChatbotAsModerator'),
        },
      },
    }),

    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
  },
}
</script>
