<template>
  <div class="widget-panel-mobile">
    <div class="widget-panel-mobile__left">
      <div class="widget-panel-mobile__qr">
        <VueQr
          class="widget-panel-mobile__qr-content"
          :class="{'widget-panel-mobile__qr-content_blured': blured}"
          :text="obsDockUrl"
          :size="360"
          :margin="0"
        />
      </div>
    </div>
    <div class="widget-panel-mobile__right">
      <div class="text-m-bold">
        {{ locale.title }}
      </div>
      <div class="text-m-regular">
        {{ locale.description }}
      </div>
      <ElButton
        class="widget-panel-mobile__toggle"
        type="primary"
        @click="blured = !blured"
      >
        {{ blured ? locale.showQR : locale.hideQR }}
      </ElButton>
      <ReloadButton
        @click="resetLink"
        :loading="reseting"
      >
        {{ locale.resetQR }}
      </ReloadButton>
    </div>
  </div>
</template>

<script>
import ReloadButton from 'components/buttons/ReloadButton'
import ElButton from 'element/ElButton'
import VueQr from 'vue-qr'

export default {
  components: {
    ElButton,
    ReloadButton,
    VueQr,
  },

  data: () => ({
    blured: true,
    reseting: false,
    qr: null,
  }),

  computed: {
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    obsDockUrl: ({ $store }) => $store.getters['widgetSettings/obsDockUrl'],
    locale: ({ $locale, $rootLocale }) => ({
      title: $locale('panel.mobileVersion.title'),
      description: $locale('panel.mobileVersion.description'),
      showQR: $rootLocale('button.showQR'),
      resetQR: $rootLocale('button.resetQR'),
      hideQR: $rootLocale('button.hideQR'),
    }),
  },

  methods: {
    async resetLink () {
      this.reseting = true

      try {
        await this.$store.dispatch('widgetSettings/refreshObsLink')
      }
      finally {
        this.reseting = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-panel-mobile {
  display: flex;
  justify-content: space-between;

  @media (max-width: 479px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  &__left {
    width: 200px;

    @media (max-width: 479px) {
      width: 100%;
    }
  }

  &__right {
    width: 220px;

    @media (max-width: 479px) {
      width: 100%;
    }

    .text-m-bold {
      margin-bottom: 8px;
    }

    ::v-deep {
      .reload-button {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  &__qr {
    width: 200px;
    height: 200px;
    border: 1px solid $--color-lightest-gray;
    border-radius: $--border-radius-base;
    padding: 10px;
    overflow: hidden;

    @media (max-width: 479px) {
      margin: 0 auto 30px;
    }

    &-content {
      position: relative;
      transform: scale(0.5);
      transform-origin: 0 0;

      &_blured {
        filter: blur(10px);
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $--color-gray;
        top: 0;
        left: 0;
      }
    }
  }

  &__toggle {
    width: 100%;
    margin-top: 24px;
  }
}
</style>
