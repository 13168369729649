<template>
  <div class="widget-status-section">
    <WidgetStatus
      :widget="widget"
      locale-name="widget"
      field-name="enabled"
    />
    <WidgetStatus
      :widget="widget"
      locale-name="stream"
      field-name="stream"
    />
    <WidgetStatus
      v-if="false"
      :widget="widget"
      locale-name="extension"
      field-name="extensionEnabled"
    />
    <WidgetStatus
      :widget="widget"
      locale-name="chatbot"
      field-name="chatbotEnabled"
    />
  </div>
</template>

<script>
import WidgetStatus from 'Streamer/pages/StreamerWidget/components/WidgetStatus'

export default {
  components: {
    WidgetStatus,
  },

  computed: {
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
  },
}
</script>

<style lang="scss" scoped>
.widget-status-section {
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;

  @media (max-width: $--tablet-portrait) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  > div:not(:first-child) {
    margin-left: 32px;

    @media (max-width: $--tablet-portrait) {
      margin: 0;
    }
  }
}
</style>
