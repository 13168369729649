<template>
  <div class="widget-status">
    <div
      v-if="localeName"
      class="widget-status__title"
    >
      {{ locale.title }}:
    </div>
    <div class="widget-status__label">
      <ElTag
        size="mini"
        effect="dark"
        :type="type"
      >
        {{ label }}
      </ElTag>
    </div>
  </div>
</template>

<script>
import ElTag from 'element/ElTag'
export default {
  components: {
    ElTag,
  },

  props: {
    localeName: VueTypes.string,
    fieldName: VueTypes.string.isRequired,
  },

  computed: {
    locale: ({ $rootLocale, localeName }) => ({
      enabled: $rootLocale('views.streamer.widget.header.enabled'),
      disabled: $rootLocale('views.streamer.widget.header.disabled'),
      title: $rootLocale(`views.streamer.widget.header.${localeName}`),
    }),
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    type: ({ widget, fieldName }) => widget[fieldName] ? 'success' : 'danger',
    label: ({ widget, locale, fieldName }) => widget[fieldName] ? locale.enabled : locale.disabled,
  },
}
</script>

<style lang="scss" scoped>
.widget-status {
  display: flex;
  align-items: center;

  @media (max-width: $--tablet-portrait) {
    justify-content: space-between;
  }

  &__title {
    margin-right: 8px;

    @media (max-width: $--tablet-portrait) {
      margin-right: 0;
    }
  }
}
</style>
