<template>
  <div class="widget-link">
    <div class="widget-link__link">
      <div
        class="widget-link__link-text"
        :class="{'widget-link__link-text_blured': bluredLink}"
      >
        {{ url }}
      </div>
      <div class="widget-link__link-toggle">
        <SvgIcon
          :name="bluredLink ? 'eye' : 'eye-close'"
          width="16px"
          height="16px"
          @click="bluredLink = !bluredLink"
        />
      </div>
    </div>
    <div class="widget-link__buttons">
      <ElButton
        :type="copyLinkState ? 'success' : 'primary'"
        @click="copyLink"
      >
        {{ copyLinkState ? locale.copyLinkSuccess : locale.copyLink }}
      </ElButton>
      <slot />
    </div>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'

export default {
  components: {
    ElButton,
  },

  props: {
    url: VueTypes.string,
  },

  data: () => ({
    copyLinkState: false,
    bluredLink: true,
  }),

  computed: {
    locale: ({ $rootLocale }) => ({
      copyLink: $rootLocale('button.copyLink.static'),
      copyLinkSuccess: $rootLocale('button.copyLink.success'),
    }),
  },

  methods: {
    copyLink () {
      this.$clipboard(this.url)

      this.copyLinkState = true
      setTimeout(() => {
        this.copyLinkState = false
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-link {
  &__link {
    display: inline-block;
    width: 100%;
    height: $--height-extra-large;
    color: $--color-text-regular;
    background-color: $--color-primary-light-8;
    border-radius: $--border-radius-base;
    padding: 0 16px;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 60px;
      height: 100%;
      background: linear-gradient(to right, rgba($--color-primary-light-8, 0), $--color-primary-light-8 30%);
      top: 0;
      right: 0;
      z-index: 1;
    }

    &-text {
      white-space: nowrap;
      line-height: $--font-line-height-extra-large;

      &_blured {
        filter: blur(3px);
      }
    }

    &-toggle {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      user-select: none;
      z-index: 5;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    @media (max-width: $--tablet-portrait) {
      grid-template-columns: 1fr;
    }

    ::v-deep {

      & > div {
        .el-button {
            width: 100%;
            margin: 0;
        }
      }
    }
  }
}
</style>
